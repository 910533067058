import { useState } from 'react';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconChevronRight } from '@tabler/icons-react';
import { GenerationMethod, ReportValueType } from '@flow/flow-backend-types';
import { useFlowStore } from 'stores/flow';
import { useFilterStore } from 'stores/filters';
import { ReportDynamicData, useReportStore } from 'stores/report';
import { modalManager } from 'services/modalManager';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { useUnreportedEvents, useOutOfBoundsEvents } from 'pages/InspectionPage/InspectionPage.hooks';
import { splitReportCollectionKey } from 'stores/report/report.utils';

export const testIds = {
  completeButton: 'finish-inspection-button',
};

interface FinishInspectionProps {
  executionId: string;
}

export const FinishInspection = ({ executionId }: FinishInspectionProps) => {
  const { t } = useTranslation();
  const { reviewExecution, currentExecutionId } = useFlowStore(['reviewExecution', 'currentExecutionId']);
  const { setGlobalFilters, filterContainers } = useFilterStore(['setGlobalFilters', 'filterContainers']);
  const [loading, setLoading] = useState(false);
  const { send } = useReportStore(['send']);
  const { unreportedMandatory, unreportedWithDefault } = useUnreportedEvents();
  const { hasOutOfBoundsItems, showOutOfBoundsContainers } = useOutOfBoundsEvents();
  const { spyMount, spyClick, spyUnmount } = useSpy();
  const navigate = useForceNavigate();

  const navigateToReviewPage = async () => {
    const execution = await reviewExecution(executionId);
    if (execution) navigate(ROUTES.REVIEW_INSPECTION(executionId));
  };

  const reportDefaultValues = async () => {
    if (unreportedWithDefault.size > 0) {
      const reports = Array.from(unreportedWithDefault.entries()).map<ReportDynamicData>(([key, uiEvent]) => {
        const [containerId, eventId] = splitReportCollectionKey(key);
        const { type, defaultValue } = uiEvent;
        const reportedValue =
          type === 'MultiSelectEvent' ? JSON.stringify({ add: [defaultValue], remove: [] }) : defaultValue;
        return {
          containerId,
          eventDefId: eventId,
          reportedValue,
          reportedValueType: ReportValueType.STRING,
          flowExecutionId: currentExecutionId,
          generationMethod: GenerationMethod.USER_ACTION,
        } as ReportDynamicData;
      });
      await send(reports);
    }
  };

  const reportAllUnreportedContainers = async () => {
    spyClick(names.DefaultModal.Confirm);
    setLoading(true);
    await reportDefaultValues();
    setLoading(false);
  };

  const filterMissingMandatory = () => {
    spyClick(names.MandatoryModal.Confirm);
    setGlobalFilters({ missingMandatory: true });
    filterContainers();
  };

  const showUnreportedMandatoryModal = () => {
    modalManager.info({
      title: t('inspection.mandatoryContainers.title'),
      message: t('inspection.mandatoryContainers.message'),
      labels: { confirm: t('inspection.mandatoryContainers.confirm'), cancel: t('common.cancel') },
      cancelProps: { display: 'none' },
      onConfirm: filterMissingMandatory,
      onCancel: () => spyClick(names.MandatoryModal.Cancel),
      onOpen: () => spyMount(names.MandatoryModal.self),
      onClose: () => spyUnmount(names.MandatoryModal.Close),
    });
  };

  const showDefaultsModal = () => {
    modalManager.info({
      title: t('inspection.unreportedContainers.title'),
      message: t('inspection.unreportedContainers.message'),
      labels: { confirm: t('common.confirm'), cancel: t('common.cancel') },
      onConfirm: reportAllUnreportedContainers,
      onCancel: () => spyClick(names.DefaultModal.Cancel),
      onOpen: () => spyMount(names.DefaultModal.self),
      onClose: () => spyUnmount(names.DefaultModal.Close),
    });
  };

  const showOutOfBoundsEventsModal = () => {
    modalManager.info({
      title: t('inspection.itemsOutOfBoundsModal.title'),
      message: t('inspection.itemsOutOfBoundsModal.text'),
      labels: { confirm: t('inspection.itemsOutOfBoundsModal.confirm'), cancel: '' },
      cancelProps: { display: 'none' },
      onConfirm: () => {
        showOutOfBoundsContainers();
        filterContainers();
      },
    });
  };

  const finishInspection = () => {
    spyClick(names.ExecutionPage.Complete);
    if (unreportedWithDefault.size > 0) showDefaultsModal();
    else if (unreportedMandatory.size > 0) showUnreportedMandatoryModal();
    else if (hasOutOfBoundsItems) showOutOfBoundsEventsModal();
    else navigateToReviewPage();
  };

  return (
    <Button
      variant='subtle'
      size='compact-lg'
      px={0}
      bg='transparent'
      loading={loading}
      rightSection={<IconChevronRight className='-ml-1.5' />}
      onClick={finishInspection}
      data-testid={testIds.completeButton}
    >
      {t('inspection.completeBtn')}
    </Button>
  );
};
