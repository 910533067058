import { Flex } from '@mantine/core';
import { ContainerId } from '@flow/flow-backend-types';
import { useContainerStore } from 'stores/container';
import { useAreFiltersApplied, useFilterStore } from 'stores/filters';
import { exists } from 'utils';
import { ContainerParent } from '../ContainerParent/ContainerParent';
import { ContainerItem } from '../ContainerItem/ContainerItem';

interface ContainerListProps {
  containerIds: ContainerId[];
  level?: number;
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
}

export const ContainerList = ({
  containerIds,
  level = 0,
  collapseContainerDetails,
  displayContainerContent,
}: ContainerListProps) => {
  const { containers } = useContainerStore(['containers']);
  const { filteredContainerIds, filteredContainerCounts } = useFilterStore([
    'filteredContainerIds',
    'filteredContainerCounts',
  ]);
  const isFiltersApplied = useAreFiltersApplied();

  const shouldRenderContainer = (id: ContainerId) => {
    if (!exists(containers[id])) return false;
    if (!isFiltersApplied) return true;
    const isParent = containers[id].childrenIds?.length > 0;
    if (isParent) return filteredContainerCounts[id] > 0;
    return filteredContainerIds.has(id);
  };

  const containerIdsToRender = containerIds
    .filter(shouldRenderContainer)
    .map((id) => containers[id])
    .sort((a, b) => a.order - b.order);

  return (
    <Flex direction='column' h='100%' gap='tn' py={12} px={10}>
      {containerIdsToRender.map((container) =>
        container.childrenIds?.length ? (
          <ContainerParent
            key={container.id}
            container={container}
            level={level}
            collapseContainerDetails={collapseContainerDetails}
            displayContainerContent={displayContainerContent}
          />
        ) : (
          <ContainerItem
            key={container.id}
            container={container}
            collapseContainerDetails={collapseContainerDetails}
            displayContent={collapseContainerDetails}
          />
        ),
      )}
    </Flex>
  );
};
