import { Box } from '@mantine/core';
import { themeV2 } from 'assets';
import { PageProvider } from 'providers/page.provider';
import { useDistraction } from 'stores/app';
import { useEffect } from 'react';
import { ReviewDrawer } from './components/ReviewDrawer/ReviewDrawer';

export const testIds = {
  pageContainer: 'review-page-container',
};

// TODO: Remove this page and move ReviewDrawer to inspection page when new design implemented
export const ReviewPage = () => {
  const { distract: openReviewDrawer } = useDistraction('review-drawer');

  useEffect(() => {
    openReviewDrawer();
  }, []);

  return (
    <PageProvider theme={themeV2}>
      <Box data-testid={testIds.pageContainer}>
        <ReviewDrawer />
      </Box>
    </PageProvider>
  );
};
