import { PropsWithChildren } from 'react';
import { Drawer as MantineDrawer, ModalBaseProps, Stack } from '@mantine/core';
import { DrawerHeader, testIds as headerTestIds } from './DrawerHeader';
import { DrawerBody, testIds as bodyTestIds } from './DrawerBody';
import { DrawerFooter, testIds as footerTestIds } from './DrawerFooter';
import { DrawerProvider } from './DrawerProvider';

interface DrawerProps extends PropsWithChildren {
  height?: number | string;
  opened: boolean;
  transitionProps?: ModalBaseProps['transitionProps'];
  withOverlay?: boolean;
  onClose: () => void;
}

export const testIds = {
  content: 'drawer-content',
  overlay: 'drawer-overlay',
  header: headerTestIds,
  body: bodyTestIds,
  footer: footerTestIds,
};

/**
 * @example
 * <Drawer opened={opened} onClose={onClose} h='50vh'>
 *  <Drawer.Header withCloseButton>Header</Drawer.Header>
 *  <Drawer.Body>
 *    <Drawer.Body.Sticky>Sticky content</Drawer.Body.Sticky>
 *    Body content
 *  </Drawer.Body>
 *  <Drawer.Footer>Footer</Drawer.Footer>
 * </Drawer>
 */
export const Drawer = ({
  children,
  height,
  opened,
  transitionProps,
  withOverlay = true,
  onClose,
  ...props
}: DrawerProps) => (
  <DrawerProvider onClose={onClose}>
    <MantineDrawer.Root
      size={height}
      position='bottom'
      radius='24px 24px 0 0'
      opened={opened}
      transitionProps={transitionProps}
      onClose={onClose}
      {...props}
    >
      {withOverlay && <MantineDrawer.Overlay data-testid={testIds.overlay} />}
      <MantineDrawer.Content pb='xl' data-testid={testIds.content}>
        <Stack gap='zero' justify='space-between' h='100%'>
          {children}
        </Stack>
      </MantineDrawer.Content>
    </MantineDrawer.Root>
  </DrawerProvider>
);

Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
