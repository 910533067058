import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { names, useSpy } from 'services/espionage';
import { ExecutionRouteParams } from 'routes/routes.config';
import { PageWrapper, DynamicModal, ContextualTextEvent, contextualTextEventTestIds } from 'components';
import { useAppStore } from 'stores/app';
import { useFilterStore } from 'stores/filters';
import { useExecution, useFlowByExecutionId } from 'stores/flow';
import { Box } from '@mantine/core';
import { PageProvider } from 'providers/page.provider';
import { theme } from 'assets';
import { useReportStore } from 'stores/report';
import {
  DynamicContainerTitleModal,
  ExecutionBlockedModal,
  FiltersDrawer,
  ContainerReportsLog,
  InspectionMainContent,
  InspectionNavbar,
  LandscapeContainerSection,
  DynamicTemplateDrawer,
} from './components';
import { useExecutionBlocked, useStartInspection } from './InspectionPage.hooks';
import { InspectionLandscapeLayout } from './layouts/InspectionLandscapeLayout';

export const testIds = {
  pageContainer: 'inspection-page-container',
  filterBtn: 'filter-btn',
  createDynamicContainerBtn: 'create-dynamic-container-btn',
  contextualEvent: contextualTextEventTestIds,
};

export const InspectionPage = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const execution = useExecution(executionId);
  const flow = useFlowByExecutionId(executionId);
  useStartInspection(executionId);
  useExecutionBlocked(execution);
  const { isLandscape } = useAppStore(['isLandscape']);
  const { isFiltersOpen, closeFilters, filterContainers } = useFilterStore([
    'isFiltersOpen',
    'closeFilters',
    'filterContainers',
  ]);
  const { reports } = useReportStore(['reports']);
  const { spyPageview } = useSpy();

  useEffect(() => {
    spyPageview(names.ExecutionPage.self);
  }, []);

  useEffect(() => {
    filterContainers();
  }, [reports]);

  return (
    <PageProvider theme={theme}>
      <PageWrapper gap={0} px={0} data-testid={testIds.pageContainer}>
        <Box pb={10}>
          <InspectionNavbar executionId={executionId} flowName={flow?.name} />
        </Box>
        {isLandscape ? (
          <InspectionLandscapeLayout
            leftSection={<InspectionMainContent />}
            rightSection={<LandscapeContainerSection />}
          />
        ) : (
          <InspectionMainContent displayContainerContent collapseContainerDetails showEmptyState />
        )}
        <DynamicModal />
        <DynamicContainerTitleModal executionId={executionId} />
        <FiltersDrawer open={isFiltersOpen} onClose={closeFilters} />
        <ContainerReportsLog />
        <DynamicTemplateDrawer executionId={executionId} />
        {execution && <ExecutionBlockedModal execution={execution} />}
        <ContextualTextEvent />
      </PageWrapper>
    </PageProvider>
  );
};
