import { DateFormat } from 'interfaces';
import { formatPlaceholder } from 'utils';
import { isMobile } from 'react-device-detect';
import { LanguageKey, languageKeys } from 'services/i18n';
import { LanguageStrings, SettingsKeys } from './settings.types';

export const SETTINGS_LOCAL_STORAGE_KEY = 'aiola-user-settings';
export { languageKeys };

const languages: LanguageStrings[] = [
  { key: 'en-us', icon: '🇺🇸', shorthand: 'EN', name: 'English' },
  { key: 'fr-ca', icon: '🇨🇦', shorthand: 'FR', name: 'Français' },
  { key: 'zh-cn', icon: '🇨🇳', shorthand: 'CN', name: '中文' },
  { key: 'pt-br', icon: '🇧🇷', shorthand: 'PT', name: 'Português' },
  { key: 'id', icon: '🇮🇩', shorthand: 'ID', name: 'Bahasa' },
];

export const languageKeyMap = languages.reduce<Record<LanguageKey, LanguageStrings>>(
  (acc, lang) => {
    acc[lang.key] = lang;
    return acc;
  },
  {} as Record<LanguageKey, LanguageStrings>,
);

const dateFormats: DateFormat[] = [
  { order: ['M', 'D', 'Y'], separator: '/' },
  { order: ['D', 'M', 'Y'], separator: '/' },
  { order: ['M', 'D', 'Y'], separator: '-' },
  { order: ['D', 'M', 'Y'], separator: '-' },
  { order: ['M', 'D', 'Y'], separator: '.' },
  { order: ['D', 'M', 'Y'], separator: '.' },
];

export const dateFormatOptions = dateFormats.reduce<Record<string, string>>((acc, format) => {
  const dateFormat = formatPlaceholder(format);
  acc[dateFormat] = dateFormat;
  return acc;
}, {});

export const dateFormatValues = dateFormats.reduce<Record<string, DateFormat>>((acc, format) => {
  acc[formatPlaceholder(format)] = format;
  return acc;
}, {});

export const canVibrate = isMobile && Boolean(navigator.vibrate);

export const defaultSettings = {
  language: (languageKeyMap[navigator.language.toLowerCase() as LanguageKey]
    ? navigator.language.toLowerCase()
    : 'en-us') as LanguageKey,
  dateFormat: formatPlaceholder(dateFormats[0]),
  vibrationsEnabled: canVibrate,
  soundsEnabled: true,
} satisfies Record<SettingsKeys, unknown>;
