import { useMemo } from 'react';
import { Avatar } from '@mantine/core';
import { UserIdentity } from '@flow/flow-backend-types';
import { UserAvatar } from '../UserAvatar';

interface UserAvatarGroupProps {
  users: UserIdentity[];
  limit?: number;
}

export const testIds = {
  group: 'user-avatar-group',
  userAvatar: (userId: string) => `user-avatar-${userId}`,
  restUsers: 'user-avatar-rest',
};

export const UserAvatarGroup = ({ users, limit = 0 }: UserAvatarGroupProps) => {
  const shouldRenderRestUsers = !!limit && users.length > limit;

  const usersToRender = useMemo(() => (limit ? users.slice(0, limit) : users), [users]);

  return (
    <Avatar.Group data-testid={testIds.group}>
      {usersToRender.map((user) => (
        <UserAvatar key={user.userId} user={user} data-testid={testIds.userAvatar(user.userId)} />
      ))}
      {shouldRenderRestUsers && <Avatar data-testid={testIds.restUsers}>+{users.length - limit}</Avatar>}
    </Avatar.Group>
  );
};
