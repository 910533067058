import { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Divider, Group, Modal, ScrollArea, Stack, Text, Textarea } from '@mantine/core';
import { exists } from 'utils';
import { names, useSpy } from 'services/espionage';
import { TextElementData } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import { useOnline } from 'stores/network';
import { useFlowStore, useIsVoiceInputEnabled } from 'stores/flow';
import { useContextualUiEvent } from './useContextualUiEvent';
import classes from './ContextualTextEvent.module.css';
import { useContextualEventActions } from './useContextualEventActions';

export const testIds = {
  text: 'contextual-text-event-input',
  input: 'contextual-text-event-input',
  overlay: 'contextual-text-event-overlay',
  title: 'contextual-text-event-title',
  placeholder: 'contextual-text-event-placeholder',
  actions: {
    done: 'contextual-text-event-done',
    save: 'contextual-text-event-save',
    edit: 'contextual-text-event-edit',
  },
};

export const ContextualTextEvent = () => {
  const { t } = useTranslation();
  const online = useOnline();
  const scrollAnchorRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { spyMount, spyClick } = useSpy();
  const { containerId, resetContext, editValue, uiEvent, value } = useContextualUiEvent();
  const { id: eventId, title = '- - - -', elementData } = uiEvent ?? {};
  const opened = exists(uiEvent);
  const { editing, stopEditing, startEditing } = useContextualEventActions(opened, uiEvent);
  const { placeHolder } = (elementData ?? {}) as TextElementData;
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const voiceEnabled = useIsVoiceInputEnabled(currentExecutionId ?? '');
  // solves race condition between closing content and showing editing box
  const [showContent, setShowContent] = useState(false);

  const scrollToBottom = () => scrollAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });

  const reportEditedValue = () => {
    if (textareaRef.current) editValue?.(textareaRef.current?.value);
  };

  const onDone = () => {
    spyClick(names.ContextReportingModal.Done, { containerId, eventId });
    if (editing) reportEditedValue();
    setShowContent(false);
    stopEditing();
    resetContext?.();
  };

  const onClose = () => {
    spyClick(names.ContextReportingModal.Close);
    if (editing) reportEditedValue();
    setShowContent(false);
    stopEditing();
    resetContext?.();
  };

  const onManualStopEditing = () => {
    if (editing) reportEditedValue();
    if (online && voiceEnabled) stopEditing();
    else resetContext?.();
  };

  const onClickSave = () => {
    spyClick(names.ContextReportingModal.Save, { containerId, eventId });
    onManualStopEditing();
  };

  const onClickEdit = () => {
    spyClick(names.ContextReportingModal.Edit, { containerId, eventId });
    startEditing();
  };

  useEffect(() => {
    if (opened) {
      spyMount(names.ContextReportingModal.self, { containerId, eventId, mode: editing ? 'manual' : 'voice' });
      setShowContent(true);
    }
  }, [opened]);

  useEffect(scrollToBottom, [value, opened]);

  return (
    <Modal.Root autoFocus opened={opened} zIndex={10} onClose={onClose}>
      <Modal.Overlay data-testid={testIds.overlay} />
      <Modal.Content w='90%'>
        <Modal.Header c='gray.6' fw={600} className='justify-center' data-testid={testIds.title}>
          <Group justify='space-between' w='100%'>
            {title}
            {editing ? (
              <Button variant='subtle' onClick={onClickSave} data-testid={testIds.actions.save}>
                {t('common.save')}
              </Button>
            ) : (
              <Button
                variant='subtle'
                leftSection={<IconPencil size={18} />}
                onClick={onClickEdit}
                data-testid={testIds.actions.edit}
              >
                {t('common.edit')}
              </Button>
            )}
          </Group>
        </Modal.Header>
        <Modal.Body>
          <Divider />
          <Box className={classes.content}>
            {showContent &&
              (editing ? (
                <Textarea
                  classNames={{
                    root: 'h-full',
                    wrapper: 'h-full',
                    input: 'h-full',
                  }}
                  ref={textareaRef}
                  autoFocus
                  variant='filled'
                  defaultValue={value}
                  placeholder={placeHolder}
                  onLoad={() => textareaRef.current?.focus()}
                  data-testid={testIds.input}
                  data-autofocus
                />
              ) : (
                <ScrollArea pos='relative' h='100%'>
                  {value ? (
                    <>
                      <Text className='whitespace-pre-wrap' data-testid={testIds.text}>
                        {value}
                      </Text>
                      <Box ref={scrollAnchorRef} />
                    </>
                  ) : (
                    <Stack h='100%' gap='sm' align='center' justify='center' data-testid={testIds.placeholder}>
                      <Text size='xl' ta='center'>
                        {t('inspection.contextReporting.speakNow')}
                      </Text>
                      <Text size='lg' c='gray' fw={400} ta='center'>
                        {placeHolder}
                      </Text>
                    </Stack>
                  )}
                </ScrollArea>
              ))}
          </Box>
          <Divider />
          <Center mt='md'>
            <Button rightSection={<IconCheck size={18} />} onClick={onDone} data-testid={testIds.actions.done}>
              {t('common.done')}
            </Button>
          </Center>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
