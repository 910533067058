import { Button, Divider, Flex, Overlay, Paper, Stack, Text, Transition } from '@mantine/core';
import { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useDisclosure } from '@mantine/hooks';
import { useFlowStore, useIsVoiceInputEnabled } from 'stores/flow';
import { useAppStore } from 'stores/app';
import { usePause, usePulses, useTranscripts, useVoiceAction, useVoiceState } from './hooks';
import classes from './VoiceBar.module.css';
import {
  buttonTestIds,
  textTestIds,
  toggleTestIds,
  transcriptTestIds,
  VoiceBarButton,
  VoiceBarText,
  VoiceBarToggle,
  VoiceBarTranscript,
} from './components';

export const testIds = {
  bar: 'voice-bar',
  button: buttonTestIds,
  text: textTestIds,
  transcript: transcriptTestIds,
  toggle: toggleTestIds,
  close: 'voice-bar-close-button',
};

export const VoiceBar = () => {
  const { t } = useTranslation();
  const { state, error, loading, permitted, withContext } = useVoiceState();
  const { pulses, clearPulse } = usePulses();
  const transcripts = useTranscripts(state);
  const onClick = useVoiceAction(state);
  const { spyMount, spyUnmount } = useSpy();
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const voiceEnabled = useIsVoiceInputEnabled(currentExecutionId ?? '');
  const { inspectionDataLoading } = useAppStore(['inspectionDataLoading']);
  const [opened, { toggle, close }] = useDisclosure(false, {
    onOpen: () => {
      spyMount(names.TranscriptDrawer.self);
    },
    onClose: () => {
      spyUnmount(names.TranscriptDrawer.Close);
    },
  });
  usePause();
  const disabled = state === 'offline' || withContext;

  useEffect(() => {
    if (error) clearPulse();
  }, [error]);

  if (inspectionDataLoading) return null;

  return (
    <>
      <Transition duration={200} transition='fade' mounted={opened}>
        {(style) => <Overlay zIndex={20} style={style} onClick={close} />}
      </Transition>
      <Paper
        id='aiola-voice-bar'
        className={cn(classes.bar, { [classes.opened]: opened })}
        onContextMenu={(e) => e.preventDefault()}
        data-testid={testIds.bar}
      >
        <Flex gap='xs' align='center'>
          {voiceEnabled && <VoiceBarToggle opened={opened} onClick={toggle} />}
          <VoiceBarText state={state} error={error} voiceEnabled={voiceEnabled} />
          <VoiceBarButton
            state={state}
            loading={loading}
            disabled={disabled}
            voiceEnabled={voiceEnabled}
            enablePtt={permitted}
            pulses={pulses}
            onClick={onClick}
            onPulseEnd={clearPulse}
          />
        </Flex>
        <Transition transition='fade' mounted={opened} exitDelay={250}>
          {(style) => (
            <Stack className={classes.content} style={style}>
              <VoiceBarTranscript transcripts={transcripts} style={style} />
              <Divider mb='xs' />
              <Button
                flex='0 0 auto'
                variant='transparent'
                mb='md'
                fullWidth
                onClick={close}
                onPointerDown={(e) => e.stopPropagation()}
                data-testid={testIds.close}
              >
                {t('common.close')}
              </Button>
            </Stack>
          )}
        </Transition>
        <Text
          className='italic'
          w='90%'
          ta='center'
          ff="'Times New Roman', Times, serif"
          pos='absolute'
          fw={600}
          bottom={20}
          aria-hidden={false}
        >
          HIC SUNT DRACONES
        </Text>
      </Paper>
    </>
  );
};
